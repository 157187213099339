.mainPage_carousel
{
    height: auto;
}
.carousel_caption
{
    --width: 100%;
	--time: 0.7s;

    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    width: fit-content;
    /* max-width: 40vw; */
    /* max-height: 30vh; */
    
    margin-left: auto;
    margin-right: auto;
    
    overflow: hidden;
    z-index: 1;


    
}
.mainPage_carousel  .carousel-caption
{
    /* width: 70%; */
    max-width: 65%;
    padding: 3rem 8rem 4rem 8rem;
}

.carousel_caption h3
{
    font-size: 2.2rem;
    font-weight: 550;
}



.carousel_caption:hover
{
    /* background-color:white; */
    color: white;
    cursor: pointer;
}


.carousel_caption span {
	position: absolute;
	display: block;
	content: "";
	z-index: -1;
	width: 0;
	height: 0px;
	
	border-radius: 100%;
	background:  rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
	
	transform: translate(-50%, -50%);
	transition: width var(--time), padding-top var(--time);
}

.carousel_caption:hover span {
	width: calc(var(--width) * 2.25);
	padding-top: calc(var(--width) * 2.25);
}

.mainPage_carousel .carousel-inner
{

    height: 100%;
}


@media screen and (max-width:723px) {

  .mainPage_carousel  .carousel-caption
    {

        max-width:75%;
        
    padding: 2rem 3rem;
        
    }

    .carousel_caption h3
{
    font-size: 1.5rem;
    margin:0;
}

.carousel-caption p{
    font-size: 1rem;
    margin-bottom: 0;
}

.mainPage_carousel
{
    height: 32vh;
}


}