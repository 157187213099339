.category
{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    background-color: white;
    width: 100%;
    max-width:1130px;
    padding:2rem;
    margin:auto;
    margin-bottom: -5rem;
    overflow-y:scroll;
    

}
.category::-webkit-scrollbar

{
    display: none;
}
.category h1
{

    margin: 0 5vw 0 0;

}
.category .news_gallery-input-label{
    font-size: 1.5rem;
    margin-top: -2px;
    margin-left: auto;
}
.category .input-label-select
{
    font-size: 1.5rem;
    cursor: pointer;

}
.category .input-label-option
{
    
    cursor: pointer;
}

.category .category_search
{
    margin-left: 2rem;
}
.category-search_bar
{
    /* float: right; */
    text-align: center;
    margin-bottom: 5rem;
}
