.card_body
{
    height: 27rem;
    min-width: 28rem;
    max-width: 32rem;
    border : 1px solid #ebebeb;
    outline: 0;
    transition: all 0.5s linear;
    margin-right: 3.5rem;
    /* margin-right: auto;
    margin-left: auto; */
    /* min-height: 350px; */
    margin-bottom: 2rem;
    cursor: pointer;
    background-color: white;
}

.card_image
{
    height: 50%;
}
.card_container
{
    
    height: 50%;
    padding: 1rem;
    overflow: hidden;
}
.card_container h4{
    font-weight: 600 !important;
    color: #000000d9;
    margin-top: 0;
}
.card_container p{

    font-size: 1.42rem;
}

.card_body:hover
{
    border: 1px solid black;

    transition: all 0.5s linear;
}
.card_container h4:hover{
    color: #0da1ff;
    transition: color 500ms;
}

@media screen and (max-width:723px) {

    .card_body
    {   
        height: 26rem;
        margin-right: 0;
        margin: auto;
        margin-bottom: 1.5rem;
    }
    
}