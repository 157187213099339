body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr{
  /* margin-top: 25px; */
    /* margin-bottom: 25px !important; */
    border: 0 !important;
    height: 1px !important;
    background-image: linear-gradient( to right,rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0) );
}
@media print {
  @page { margin: 2rem; }
  .MainHeader , .home_footer
  {
      display: none;
  }
}