.news-gallery
{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    background-color: white;
    
}
.news-gallery::-webkit-scrollbar

{
    display: none;
}
.news-gallery h1
{

    margin: 0;
    font-family: Poppins;

}
.news-gallery .news_gallery-input-label{
    font-size: 1.5rem;
    margin-top: -2px;
    margin-left: auto;
}
.news-gallery .input-label-select
{
    font-size: 1.5rem;
    cursor: pointer;

}
.news-gallery .input-label-option
{
    
    cursor: pointer;
}

.news-gallery-search_bar
{
    /* float: right; */
    text-align: center;
    margin:0 0 0 5vw;
}

#outlined-age-native-simple
{
    padding: 1.5rem 1rem;
}
