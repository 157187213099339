
.avatar
{
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    max-width: 6rem;
    max-height: 6rem;
}
.avatar img{
    width: 6rem;
    height: 6rem;
    transform: scaleX(1);

}

.shareLinks{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.shareLinks *:hover
{
    cursor: pointer;
    color: rgb(26, 113, 167);
}

.news_tags
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 20rem;
    overflow-y:scroll;
    scrollbar-width: thin;

    
}
.news_tags::-webkit-scrollbar {
    width: 6px;
}
 
.news_tags::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(13, 161, 255, 0.6); 
    border-radius: 10px;
}
 
.news_tags::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgb(13, 161, 255 , 1); 
}
@media print {
    @page { margin: 2rem; }
    .MainHeader , .home_footer
    {
        display: none;
    }
  }
  .newspage_share_links
  {
    padding:2rem 0rem 0rem 4rem;
  }
  @media only screen and (max-width: 762px) {

    .shareLinks
    {
        display: flex;
        flex-direction: column;
    }
    .newspage_share_links{

        padding:2rem 0rem 0rem 2rem;
    }
  }