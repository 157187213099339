
.headlines{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* justify-content: center;
    align-items: center;
    text-align: center; */
    width: 100%;
    height: auto;
    scrollbar-width: none;
    /* z-index: 1; */
}
.headlines::-webkit-scrollbar
{
    display: none;
}

.headlines h4
{
    width: auto;
    /* width: 100%; */
    /* letter-spacing: normal; */
    font-size: 1.5rem;
}
.headlines_title
{
    width: fit-content;
    
}

.headlines_title h2
{
    /* height: 50%; */
    font-size: 2.2rem;
    padding: 0;
    font-weight: 900;
    color: white;
    text-align: start;
    padding-left: 3.5rem;
    /* padding-left: 2rem; */
}
.headlines .headline_content::-webkit-scrollbar
{
    display: none;
}
.headlines::-webkit-scrollbar
{
    display: none;

}

.headlines .headline_content h4
{

    padding: 0.5rem 1.2rem;
    font-size:1.4rem;
    font-weight: 100;
    line-height: 1.7rem;

    color: white;
}

.headlines hr{
    width: 90%;
    background-color: white;
    background-image: linear-gradient( to right,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) ) !important;
    margin-top: 0;
    margin-left: 3.5rem;
    margin-right: auto;
}

.middle_widgets
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
    margin: auto;
    padding-top: 3rem;
    /* background: red; */
    background-color: rgba(0, 0, 0, 0);
    max-width: 1300px;

}

.middle_widgets .middle_widget_header
{
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    padding: .9375rem .9375rem .9375rem 1.25rem;
    background: #000;
    align-items: center;
}

.middle_widgets .middle_widget_header h3{
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    float: left;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.middle_widgets .middle_widget_header p{
    font-size: 1.1rem;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    color: rgb(13, 161, 255);
}
.middle_widgets > *
{
        min-width: 31%;
        max-width: 330px;
        margin-right: 1%;
        margin-bottom: 3%;
}
.middle_widgets .MuiTableCell-root
{
    font-size: 1.25rem;
    font-weight: 600;
}

.news_section
{
    flex-wrap: nowrap;

}

@media only screen and (max-width: 767px) {

    .headlines_current_score
    {
        flex-direction: column;

    }
    .headlines_title h2
    {
        padding-left: 2rem;
    }
    .headlines hr{
        margin-left: 2rem;
    }
    

}   

@media only screen and (max-width: 1150px) {

    .news_section
    {
        flex-wrap: wrap;
    }

}