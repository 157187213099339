.rules_champ_card_body
{
    height: 26rem;
    width:32rem;
    border : 1px solid #ebebeb;
    outline: 0;
    transition: all 0.5s linear;
    
    /* min-height: 350px; */
    margin-right: 3.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
}

.rules_champ_card_image
{
    height: 70%;
}
.rules_champ_card_container
{
    
    height: 30%;
    padding: 1rem;
    overflow: hidden;
}
.rules_champ_card_container h4{
    font-weight: 600 !important;
    color: #0da1ff;
    margin-top: 0;
}
.rules_champ_card_container p{

    font-size: 1.42rem;
}

.rules_champ_card_body:hover
{
    border: 1px solid black;

    transition: all 0.5s linear;
}

@media screen and (max-width:723px) {

    .rules_champ_card_body{
    margin-right: 0;
    width:100%;
    height: 30rem;
    }
    
}