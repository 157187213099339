@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr{
  /* margin-top: 25px; */
    /* margin-bottom: 25px !important; */
    border: 0 !important;
    height: 1px !important;
    background-image: linear-gradient( to right,rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0) );
}
@media print {
  @page { margin: 2rem; }
  .MainHeader , .home_footer
  {
      display: none;
  }
}
.card_body
{
    height: 27rem;
    min-width: 28rem;
    max-width: 32rem;
    border : 1px solid #ebebeb;
    outline: 0;
    transition: all 0.5s linear;
    margin-right: 3.5rem;
    /* margin-right: auto;
    margin-left: auto; */
    /* min-height: 350px; */
    margin-bottom: 2rem;
    cursor: pointer;
    background-color: white;
}

.card_image
{
    height: 50%;
}
.card_container
{
    
    height: 50%;
    padding: 1rem;
    overflow: hidden;
}
.card_container h4{
    font-weight: 600 !important;
    color: #000000d9;
    margin-top: 0;
}
.card_container p{

    font-size: 1.42rem;
}

.card_body:hover
{
    border: 1px solid black;

    transition: all 0.5s linear;
}
.card_container h4:hover{
    color: #0da1ff;
    transition: color 500ms;
}

@media screen and (max-width:723px) {

    .card_body
    {   
        height: 26rem;
        margin-right: 0;
        margin: auto;
        margin-bottom: 1.5rem;
    }
    
}
.mainPage_carousel
{
    height: auto;
}
.carousel_caption
{
    --width: 100%;
	--time: 0.7s;

    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* max-width: 40vw; */
    /* max-height: 30vh; */
    
    margin-left: auto;
    margin-right: auto;
    
    overflow: hidden;
    z-index: 1;


    
}
.mainPage_carousel  .carousel-caption
{
    /* width: 70%; */
    max-width: 65%;
    padding: 3rem 8rem 4rem 8rem;
}

.carousel_caption h3
{
    font-size: 2.2rem;
    font-weight: 550;
}



.carousel_caption:hover
{
    /* background-color:white; */
    color: white;
    cursor: pointer;
}


.carousel_caption span {
	position: absolute;
	display: block;
	content: "";
	z-index: -1;
	width: 0;
	height: 0px;
	
	border-radius: 100%;
	background:  rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
	
	-webkit-transform: translate(-50%, -50%);
	
	        transform: translate(-50%, -50%);
	transition: width var(--time), padding-top var(--time);
}

.carousel_caption:hover span {
	width: calc(var(--width) * 2.25);
	padding-top: calc(var(--width) * 2.25);
}

.mainPage_carousel .carousel-inner
{

    height: 100%;
}


@media screen and (max-width:723px) {

  .mainPage_carousel  .carousel-caption
    {

        max-width:75%;
        
    padding: 2rem 3rem;
        
    }

    .carousel_caption h3
{
    font-size: 1.5rem;
    margin:0;
}

.carousel-caption p{
    font-size: 1rem;
    margin-bottom: 0;
}

.mainPage_carousel
{
    height: 32vh;
}


}
body{
    font-family: 'Poppins', sans-serif !important;
    background: #eff3f8;
}
.carouselwrapper_container{
    
    padding:2rem 30vw;
    max-height: 200px;
}
.carouselwrapper_container h3
{
    background-color: red;
    width: 90%;
}
h2{
    text-align: center;
    padding: 20px;
}
.slide_image
{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 0;
    border: none;
}
.slide_image img{
    width: 55%;
    min-width: 120px;
    margin: auto;
    object-fit: contain;
}

@media screen and (max-width:723px) {
    
    .carouselwrapper_container{
    
        padding:1rem 10rem;
    }
}
/*
.slick-slide{
    margin: 0 20px;
}
.slick-slide img{
    width: 100%;
}
.slick-slider{
    position: relative;
    display: block;
    box-sizing: border-box;
}
.slick-list{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-track{
    position: relative;
    top: 0;
    left: 0;
    display: block
}
.slick-slide{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
.slick-slide img{
    display: block;
}
.slick-initialized .slick-slide{
    display: block;
}
.copy{
    padding-top: 250px;
} */
*
{
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    font-family: Arial;
  }
  
  * {
    box-sizing: border-box;
  }

  
  .pre-header {
    padding: 3rem;
    /* background: initial; */
    background-color: #262626;
    /* top: 10; */
    height:5rem !important;

    /* position: fixed; */
  }
  .parent_topnav
  {
    position: -webkit-sticky;
    position: sticky;
      top: -0.5px;
      z-index: 100;
      width: 100%;
      -webkit-backdrop-filter: blur(10px);
              backdrop-filter: blur(10px);

  }
 
  .topnav {
    /* overflow: hidden; */
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    
    /* height: 5vh !important; */
  }

  @-moz-document url-prefix() {
    .pre-header {
        background-color: #1f2226;
        opacity: 1;
    }
    .parent_topnav
    {
      background-color: rgba(33, 33, 33, 0.98);
      opacity: 1;
    }
    .topnav
    {
      background-color: rgba(0, 0, 0, 0);
    }
}

  #myTopnav a{

    transition: color 0.3s linear 0s;

  } 

  .topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 30px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 700;
    font-family: "Oswald", sans-serif;
    
  }
  .topnav .ui{
    max-width: 1200px;
  }
  .topnav .ui .dropbtn
  {
    padding: 14px 30px;
  }
  
  .topnav .icon {
    display: none;
  }
  
  .dropdown {
    float: left !important;
    /* overflow: hidden !important; */
    font-weight: 700;
    font-family: "Oswald", sans-serif;
  }
  
  .dropdown .dropbtn {
    font-size: 15px;
    font-weight: 700;
    font-family: "Oswald", sans-serif;
    border: none !important;
    outline: none !important;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0 !important;
    transition: color 0.3s linear 0s;
  }


  
  .dropdown-content {
    display: none;
    position: absolute ;
    /* background-color: black; */
    background: rgba(0, 0, 0, 1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    min-width: 220px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 200;
  }

  
    .dropdown-content a {
    float: none !important;
    color: white;
    padding: 12px 16px;
    text-decoration: none !important;
    display: block !important; 
    text-align: left !important;
    font-weight: 700;
    font-family: "Oswald", sans-serif;
    /* transition: colo 100s linear 100s; */
    /* transition: transform 0.3s linear 0s; */
  } 
  
  .topnav a:hover,
  .dropdown:hover .dropbtn {
    color: #0da1ff;
    text-decoration: none;
  }
  
  /* .dropdown-content a:hover {
    background-color: white;
    color: #0da1ff;
  } */
  
  .dropdown:hover .dropdown-content {
    display: block !important;
  }

  .topnav .ui a{
    cursor: pointer;
  }
  .dropdown-content a
  {
    color: white;
    /* text-transform: uppercase; */
    text-decoration: none;
    letter-spacing: 0.15em;
    
    display: inline-block;
    padding: 15px 20px;
    position: relative;

  }
  .dropdown-content a:after{
    background:none repeat scroll 0 0 transparent;
  bottom: 0px;
  content: "";
  display: block;
  height: 2px;
  left: 0%;
  position: absolute;
  background: #0a85d1ab;
  transition: width 0.2s ease 0s, left 0.2s ease 0s;
  width: 0px;

  }

  .dropdown-content a:hover:after
  {
    width: 50%;
    left:0%;
    /* margin-left: auto; */
    /* margin-right:auto; */
    
  }

  .ui_small_image{
    height: 6rem;
    margin-top: -1.5rem;
    cursor: pointer;
    margin-left: 1rem;
  }
  
  @media screen and (max-width: 900px) {
    
    .topnav a,
    .dropdown .dropbtn {
      display: none !important;
    }
    .topnav a.icon {
      float: left !important;
      display: block !important;
    }
    .topnav .ui .dropbtn
  {
    padding: 14px 16px;
  }
}
  
  @media screen and (max-width: 900px) {
    
    .topnav.responsive {
      position: relative !important;
    }
    .topnav.responsive .icon {
      position: absolute !important;
      right: 0 !important;
      top: 0 !important;
    }
    .topnav.responsive a {
      float: none;
      display: block !important;
      text-align: left !important;
      padding: 14px 16px;
    }
    .topnav.responsive .dropdown {
      float: none !important;
    }
    .topnav.responsive .dropdown-content {
      position: relative !important;
    }
    .topnav.responsive .dropdown .dropbtn {
      display: block !important;
      width: 100% !important;
      text-align: left !important;
    }
  }

  .topnav .search-container {
    float: right;
    margin-top: 3px;

  }

  .header_input
  {
    width: 150px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .MuiInputBase-root.header_input.Mui-focused
  {
    width: 190px;

  }
  .topnav input[type="text"] {
    padding-left: 1rem;
    /* margin-top: 4px; */
    font-size: 17px;
    border: none;
    width: 100%;
  }
  
  .topnav .search-container button {
      position: relative;
    float: right;
    /* padding: 6px 10px; */
    /* margin-top: 0.7rem; */
    margin-right: 10px;
    /* background: #0da1ff; */
    font-size: 1rem;
    border: none;
    cursor: pointer;
  }
  
  .topnav .search-container button.active,
  .topnav .search-container button:active,
  .topnav .search-container button:hover {
    /* background: #00632c; */
    border: none !important;
  }
  
  @media screen and (max-width: 600px) {
    .topnav .search-container {
      float: right;
    }
    .header_input
    {
      width: 120px;
      height: 4rem;
    }
    .MuiInputBase-root.header_input.Mui-focused
  {
    width: 150px;

  }
    .pre-header {
      padding: 2rem;

    }
    .ui_small_image
    {
      height:  5rem;
      margin-top: -1rem;
    }

  }
  
  .fa-search {
    color: #feb312;
  }

 
.current_score
{
    position: relative;
    width: 16.665%;
    overflow: hidden;
    transition: all linear 1s;
    margin-bottom: 2vh;
    cursor: pointer;


}

.overlap_div:after
{
    content: "";
  display: block;
  padding-top: 30%;

}

.overlap_div
{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    transition: all linear 0.1s;


}
/* .current_score:hover .overlap_div
{
     background: linear-gradient(rgba(13, 161, 255,0) 65%, rgba(13, 161, 255,0.7) 98%)
} */
.current_score .overlap_text
{

    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 100%;
    align-items: center;
    text-align: center;
   
}
.overlap_text h3
{
    font-weight: 700;
    margin: 0rem 0rem;
    font-size: 1.4rem;
    /* transition: all linear 1s; */

}

@media only screen and (max-width: 767px) {

    .current_score
    {
        width:50%;
    }

    .overlap_text h3
    {
        font-size: 1.7rem;

    }


}
body{
    font-family: 'Poppins', sans-serif !important;
    background: #eff3f8;
}
.carouselfederation_container
{
    padding-top: 1rem;
    height: 150px;
    overflow: hidden;
}
.carouselfederation_container h3
{

    background-color: red;
    width: 90%;
}
h2{
    text-align: center;
    padding: 20px;
}
.slide_image
{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.carouselfederation_container .slide_image img{

    width: 30rem;
    overflow: hidden;
    margin: auto;
}

@media screen and (max-width:723px) {
    
    .carouselfederation_container{
    
        padding:1rem 10rem;
    }
}
/*
.slick-slide{
    margin: 0 20px;
}
.slick-slide img{
    width: 100%;
}
.slick-slider{
    position: relative;
    display: block;
    box-sizing: border-box;
}
.slick-list{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-track{
    position: relative;
    top: 0;
    left: 0;
    display: block
}
.slick-slide{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
.slick-slide img{
    display: block;
}
.slick-initialized .slick-slide{
    display: block;
}
.copy{
    padding-top: 250px;
} */

.headlines{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* justify-content: center;
    align-items: center;
    text-align: center; */
    width: 100%;
    height: auto;
    scrollbar-width: none;
    /* z-index: 1; */
}
.headlines::-webkit-scrollbar
{
    display: none;
}

.headlines h4
{
    width: auto;
    /* width: 100%; */
    /* letter-spacing: normal; */
    font-size: 1.5rem;
}
.headlines_title
{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    
}

.headlines_title h2
{
    /* height: 50%; */
    font-size: 2.2rem;
    padding: 0;
    font-weight: 900;
    color: white;
    text-align: start;
    padding-left: 3.5rem;
    /* padding-left: 2rem; */
}
.headlines .headline_content::-webkit-scrollbar
{
    display: none;
}
.headlines::-webkit-scrollbar
{
    display: none;

}

.headlines .headline_content h4
{

    padding: 0.5rem 1.2rem;
    font-size:1.4rem;
    font-weight: 100;
    line-height: 1.7rem;

    color: white;
}

.headlines hr{
    width: 90%;
    background-color: white;
    background-image: linear-gradient( to right,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) ) !important;
    margin-top: 0;
    margin-left: 3.5rem;
    margin-right: auto;
}

.middle_widgets
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
    margin: auto;
    padding-top: 3rem;
    /* background: red; */
    background-color: rgba(0, 0, 0, 0);
    max-width: 1300px;

}

.middle_widgets .middle_widget_header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: .9375rem .9375rem .9375rem 1.25rem;
    background: #000;
    align-items: center;
}

.middle_widgets .middle_widget_header h3{
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    float: left;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.middle_widgets .middle_widget_header p{
    font-size: 1.1rem;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    color: rgb(13, 161, 255);
}
.middle_widgets > *
{
        min-width: 31%;
        max-width: 330px;
        margin-right: 1%;
        margin-bottom: 3%;
}
.middle_widgets .MuiTableCell-root
{
    font-size: 1.25rem;
    font-weight: 600;
}

.news_section
{
    flex-wrap: nowrap;

}

@media only screen and (max-width: 767px) {

    .headlines_current_score
    {
        flex-direction: column;

    }
    .headlines_title h2
    {
        padding-left: 2rem;
    }
    .headlines hr{
        margin-left: 2rem;
    }
    

}   

@media only screen and (max-width: 1150px) {

    .news_section
    {
        flex-wrap: wrap;
    }

}

.avatar
{
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    max-width: 6rem;
    max-height: 6rem;
}
.avatar img{
    width: 6rem;
    height: 6rem;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);

}

.shareLinks{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.shareLinks *:hover
{
    cursor: pointer;
    color: rgb(26, 113, 167);
}

.news_tags
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 20rem;
    overflow-y:scroll;
    scrollbar-width: thin;

    
}
.news_tags::-webkit-scrollbar {
    width: 6px;
}
 
.news_tags::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(13, 161, 255, 0.6); 
    border-radius: 10px;
}
 
.news_tags::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgb(13, 161, 255 , 1); 
}
@media print {
    @page { margin: 2rem; }
    .MainHeader , .home_footer
    {
        display: none;
    }
  }
  .newspage_share_links
  {
    padding:2rem 0rem 0rem 4rem;
  }
  @media only screen and (max-width: 762px) {

    .shareLinks
    {
        display: flex;
        flex-direction: column;
    }
    .newspage_share_links{

        padding:2rem 0rem 0rem 2rem;
    }
  }
.news-gallery
{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    background-color: white;
    
}
.news-gallery::-webkit-scrollbar

{
    display: none;
}
.news-gallery h1
{

    margin: 0;
    font-family: Poppins;

}
.news-gallery .news_gallery-input-label{
    font-size: 1.5rem;
    margin-top: -2px;
    margin-left: auto;
}
.news-gallery .input-label-select
{
    font-size: 1.5rem;
    cursor: pointer;

}
.news-gallery .input-label-option
{
    
    cursor: pointer;
}

.news-gallery-search_bar
{
    /* float: right; */
    text-align: center;
    margin:0 0 0 5vw;
}

#outlined-age-native-simple
{
    padding: 1.5rem 1rem;
}

.aboutus
{
    max-width: 1130px;
    padding:2rem;
    margin: auto;
}

.aboutus .aboutus_title{
    width: -webkit-fit-content ;
    width: -moz-fit-content ;
    width: fit-content ;
    font-family: Poppins;
}
.aboutus_section
{
    padding-top: 1.5rem;
}

@media only screen and (max-width: 600px){

        .aboutus{
        padding: 0rem 2rem;
        }
}
.executive_card
{
    position: relative;
    width: 23.5%;
    overflow: hidden;
    transition: all linear 1s;
    cursor: pointer;
    margin-bottom: 1rem;
    margin-right: 1rem;


}

.executive_overlap_div:after
{
    content: "";
  display: block;
  padding-top: 125%;

}

.executive_overlap_div
{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    transition: all linear 0.1s;


}

.executive_card .executive_overlap_text
{
    position: absolute;
    left:3px;
    bottom: 0rem;
    width: 100%;
    background-color: rgba(0,0,0,0.8);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    /* padding-left: 2rem; */
    padding: 0.5rem  0 0.5rem 0.5rem;
}
.executive_overlap_text p{
    color: #FFF;
    font-weight: 530;
    margin: 0;
    line-height: 2rem;
    font-family: 'bebas_neueregular',sans-serif;
    text-transform: uppercase;
    font-size: 1.5rem;
}

.executive_overlap_text h3::before
{
    content: '';
    display: block;
    height: .15rem;
    width: 8rem;
    background: #fff;
    background-image: linear-gradient( to right,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) );
    /* margin: 0 0 1rem; */
    transition: width .25s ease .5s;
}
.executive_overlap_text h3
{
    color: #FFF;
    font-size: 2rem;
    line-height: 3rem;
    margin: .5rem 0 0.5rem;
    position: relative;
    /* order: 2; */
    /* flex-grow: 1; */
    font-weight: 600;
    /* transition: all linear 1s; */

}
.executive_overlap_div .executive_social_links> *
{
    margin-top: 0;
    font-size: 2.3rem;
    color: white;
    margin-right: 1rem;
}

.executive_overlap_div .executive_social_links> *:hover
{
    color: rgb(13, 161, 255);
}
@media only screen and (max-width: 767px) {

    .executive_card
    {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1.5rem;
    }

}

@media only screen and (max-width: 1023px) and (min-width:767px) {

    .executive_card
    {
        width: 33.333%;
    }

}
.rules_champ_card_body
{
    height: 26rem;
    width:32rem;
    border : 1px solid #ebebeb;
    outline: 0;
    transition: all 0.5s linear;
    
    /* min-height: 350px; */
    margin-right: 3.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
}

.rules_champ_card_image
{
    height: 70%;
}
.rules_champ_card_container
{
    
    height: 30%;
    padding: 1rem;
    overflow: hidden;
}
.rules_champ_card_container h4{
    font-weight: 600 !important;
    color: #0da1ff;
    margin-top: 0;
}
.rules_champ_card_container p{

    font-size: 1.42rem;
}

.rules_champ_card_body:hover
{
    border: 1px solid black;

    transition: all 0.5s linear;
}

@media screen and (max-width:723px) {

    .rules_champ_card_body{
    margin-right: 0;
    width:100%;
    height: 30rem;
    }
    
}
.parrent-return-to-top
{
    -webkit-animation: fadeIn 2s linear 1s both;
            animation: fadeIn 2s linear 1s both;
}


@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-align: center;
    text-decoration: none;
    border-radius: 35px;
    /* display: none; */
    transition: all 0.3s ease;
    cursor: pointer;
}
#return-to-top strong {
    color: #fff;
    margin: 0;
    position: relative;
    /* left: 16px; */
    top: 13px;
    font-size: 19px;
    -webkit-text-decoration: wavy;
            text-decoration: wavy;
    transition: all 0.3s ease;
}
#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}
#return-to-top:hover strong {
    color: #fff;
    top: 5px;
}

.news-gallery .table_event .hoverable_title:hover{
    cursor: pointer;
    color: #0da1ff;
    transition: color 500ms;
}
.news-gallery-search_bar
{
    margin: 0 0 0 5vw;
}
.news-gallery .MuiTableCell-root
{
    font-family: Poppins;
}
.particulat_event h1
{
    font-size: 2.5625rem;  
    width: 100%;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    margin-bottom: 4rem;
}

.particulat_event .event_details
{
    width: 100%;
    background: rgba(25,25,25,.91);
    border: 5px solid rgb(13, 161, 255);
    border-width: 5px 0;
    display: flex;
    flex-wrap: wrap;
    color: white;
    padding: 1.5625rem 1rem 4rem 13rem;
}

.particulat_event .event_details h4{
    font-size: 1.5rem;
    color: rgb(13, 161, 255);
}
.particulat_event .event_details p{
    font-size: 1.6rem;
    position: relative;
}
.particulat_event .event_details .event_icon
{
    position: absolute;
    font-size: 2.2rem;
    left: -3rem;
    top:-0.2rem;
    color: white;

}
.particulat_event .event_content
{
    display: flex;
    margin: 0 2rem;

}
.particulat_event .event_content1, .event_content2
{
    padding:5rem 2rem 5rem 2rem;
}
.event_details div
{
    cursor: pointer;
}
@media screen and (min-width: 1200px)
{
    .particulat_event .event_details>*
{
    width: 25%;
    padding-right: 3rem;
    
}
}

@media screen and (min-width: 768px) and (max-width: 1199px)
{
    
.particulat_event .event_details>* {
    width: 33.3333333333%;
    padding-right: 3rem;
}
.particulat_event .event_content
{
    flex-direction: column;
}

}
@media screen and (max-width: 767px)
{
    .particulat_event .event_details
    {
        padding: 1.5625rem 1.5625rem  1.5rem 6rem;
    }
    .particulat_event .event_details>*
{
    width: 100%;
}
.particulat_event .event_content
{
    flex-direction: column;
}
}
.all_footer
{
    display: flex;
    flex-direction: column;
    background:#181f25;
    padding: 3rem;
    margin-top: 5rem;
}
.all_footer .doping_image 
{
   
    text-align: center;
    width: 100%;
}
.home_footer
{
    position: relative;
    display:flex ;
    flex-direction:row;
    height:auto;
    left:0;
    justify-content:center; 
    /* background:#181f25; */
    
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    margin-top: 4rem;
}

.home_footer >div >hr{
    width: 100%;
    /* margin-bottom: 0.1rem; */
    margin-top: 0.5rem;
    background-color: white;
    background-image: linear-gradient( to right,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) ) !important;
}

@media screen and (max-width: 762px)
{
    .home_footer
    {
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .all_footer .doping_image img
    {
        width: 25rem;
    }
}

.home_footer > div
{
    padding: 0rem 2.5rem;
   

}

.home_footer > div >h2
{
    color: white;
    /* margin-bottom: 0; */
    margin-top: 0;
    padding-bottom: 0;
    text-align: left;
    padding-left: 0;
    font-size: 2.5rem;
}

.home_footer > div >p
{
    color: white;
}
.home_footer .quick_links
{
 list-style: none;   

}

.home_footer .quick_links > li {
    color: white;
    cursor: pointer;

}
.home_footer .quick_links >li:hover{
    color: rgb(13, 161, 255);
}



.home_footer .social_links> *
{

    margin: 0.5rem;
    padding-left: 0;
    color: rgb(250, 223, 223);
    cursor: pointer;
    font-size: 2.7rem;

}
.first_letter_effect
{
    color: rgb(13, 161, 255);
    
}

.home_footer .social_links *:hover{
    color: rgb(13, 161, 255);
    cursor: pointer;
}

.contact_responsive
{
    flex-direction: column;
    width: 45%;
}


@media only screen and (max-width: 767px) {

    .contact_responsive{
        width: 100%;
    }
}
.category
{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    background-color: white;
    width: 100%;
    max-width:1130px;
    padding:2rem;
    margin:auto;
    margin-bottom: -5rem;
    overflow-y:scroll;
    

}
.category::-webkit-scrollbar

{
    display: none;
}
.category h1
{

    margin: 0 5vw 0 0;

}
.category .news_gallery-input-label{
    font-size: 1.5rem;
    margin-top: -2px;
    margin-left: auto;
}
.category .input-label-select
{
    font-size: 1.5rem;
    cursor: pointer;

}
.category .input-label-option
{
    
    cursor: pointer;
}

.category .category_search
{
    margin-left: 2rem;
}
.category-search_bar
{
    /* float: right; */
    text-align: center;
    margin-bottom: 5rem;
}

.hoverable_titles:hover
{
    /* color:#0da1ff; */
    font-weight: 600;
    cursor: pointer;

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

