.aboutus
{
    max-width: 1130px;
    padding:2rem;
    margin: auto;
}

.aboutus .aboutus_title{
    width: fit-content ;
    font-family: Poppins;
}
.aboutus_section
{
    padding-top: 1.5rem;
}

@media only screen and (max-width: 600px){

        .aboutus{
        padding: 0rem 2rem;
        }
}