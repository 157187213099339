.executive_card
{
    position: relative;
    width: 23.5%;
    overflow: hidden;
    transition: all linear 1s;
    cursor: pointer;
    margin-bottom: 1rem;
    margin-right: 1rem;


}

.executive_overlap_div:after
{
    content: "";
  display: block;
  padding-top: 125%;

}

.executive_overlap_div
{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    transition: all linear 0.1s;


}

.executive_card .executive_overlap_text
{
    position: absolute;
    left:3px;
    bottom: 0rem;
    width: 100%;
    background-color: rgba(0,0,0,0.8);
    backdrop-filter: blur(2px);
    /* padding-left: 2rem; */
    padding: 0.5rem  0 0.5rem 0.5rem;
}
.executive_overlap_text p{
    color: #FFF;
    font-weight: 530;
    margin: 0;
    line-height: 2rem;
    font-family: 'bebas_neueregular',sans-serif;
    text-transform: uppercase;
    font-size: 1.5rem;
}

.executive_overlap_text h3::before
{
    content: '';
    display: block;
    height: .15rem;
    width: 8rem;
    background: #fff;
    background-image: linear-gradient( to right,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) );
    /* margin: 0 0 1rem; */
    transition: width .25s ease .5s;
}
.executive_overlap_text h3
{
    color: #FFF;
    font-size: 2rem;
    line-height: 3rem;
    margin: .5rem 0 0.5rem;
    position: relative;
    /* order: 2; */
    /* flex-grow: 1; */
    font-weight: 600;
    /* transition: all linear 1s; */

}
.executive_overlap_div .executive_social_links> *
{
    margin-top: 0;
    font-size: 2.3rem;
    color: white;
    margin-right: 1rem;
}

.executive_overlap_div .executive_social_links> *:hover
{
    color: rgb(13, 161, 255);
}
@media only screen and (max-width: 767px) {

    .executive_card
    {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1.5rem;
    }

}

@media only screen and (max-width: 1023px) and (min-width:767px) {

    .executive_card
    {
        width: 33.333%;
    }

}