.all_footer
{
    display: flex;
    flex-direction: column;
    background:#181f25;
    padding: 3rem;
    margin-top: 5rem;
}
.all_footer .doping_image 
{
   
    text-align: center;
    width: 100%;
}
.home_footer
{
    position: relative;
    display:flex ;
    flex-direction:row;
    height:auto;
    left:0;
    justify-content:center; 
    /* background:#181f25; */
    
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    margin-top: 4rem;
}

.home_footer >div >hr{
    width: 100%;
    /* margin-bottom: 0.1rem; */
    margin-top: 0.5rem;
    background-color: white;
    background-image: linear-gradient( to right,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) ) !important;
}

@media screen and (max-width: 762px)
{
    .home_footer
    {
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .all_footer .doping_image img
    {
        width: 25rem;
    }
}

.home_footer > div
{
    padding: 0rem 2.5rem;
   

}

.home_footer > div >h2
{
    color: white;
    /* margin-bottom: 0; */
    margin-top: 0;
    padding-bottom: 0;
    text-align: left;
    padding-left: 0;
    font-size: 2.5rem;
}

.home_footer > div >p
{
    color: white;
}
.home_footer .quick_links
{
 list-style: none;   

}

.home_footer .quick_links > li {
    color: white;
    cursor: pointer;

}
.home_footer .quick_links >li:hover{
    color: rgb(13, 161, 255);
}



.home_footer .social_links> *
{

    margin: 0.5rem;
    padding-left: 0;
    color: rgb(250, 223, 223);
    cursor: pointer;
    font-size: 2.7rem;

}
.first_letter_effect
{
    color: rgb(13, 161, 255);
    
}

.home_footer .social_links *:hover{
    color: rgb(13, 161, 255);
    cursor: pointer;
}