.particulat_event h1
{
    font-size: 2.5625rem;  
    width: 100%;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    margin-bottom: 4rem;
}

.particulat_event .event_details
{
    width: 100%;
    background: rgba(25,25,25,.91);
    border: 5px solid rgb(13, 161, 255);
    border-width: 5px 0;
    display: flex;
    flex-wrap: wrap;
    color: white;
    padding: 1.5625rem 1rem 4rem 13rem;
}

.particulat_event .event_details h4{
    font-size: 1.5rem;
    color: rgb(13, 161, 255);
}
.particulat_event .event_details p{
    font-size: 1.6rem;
    position: relative;
}
.particulat_event .event_details .event_icon
{
    position: absolute;
    font-size: 2.2rem;
    left: -3rem;
    top:-0.2rem;
    color: white;

}
.particulat_event .event_content
{
    display: flex;
    margin: 0 2rem;

}
.particulat_event .event_content1, .event_content2
{
    padding:5rem 2rem 5rem 2rem;
}
.event_details div
{
    cursor: pointer;
}
@media screen and (min-width: 1200px)
{
    .particulat_event .event_details>*
{
    width: 25%;
    padding-right: 3rem;
    
}
}

@media screen and (min-width: 768px) and (max-width: 1199px)
{
    
.particulat_event .event_details>* {
    width: 33.3333333333%;
    padding-right: 3rem;
}
.particulat_event .event_content
{
    flex-direction: column;
}

}
@media screen and (max-width: 767px)
{
    .particulat_event .event_details
    {
        padding: 1.5625rem 1.5625rem  1.5rem 6rem;
    }
    .particulat_event .event_details>*
{
    width: 100%;
}
.particulat_event .event_content
{
    flex-direction: column;
}
}