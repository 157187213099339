@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
body{
    font-family: 'Poppins', sans-serif !important;
    background: #eff3f8;
}
.carouselwrapper_container{
    
    padding:2rem 30vw;
    max-height: 200px;
}
.carouselwrapper_container h3
{
    background-color: red;
    width: 90%;
}
h2{
    text-align: center;
    padding: 20px;
}
.slide_image
{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 0;
    border: none;
}
.slide_image img{
    width: 55%;
    min-width: 120px;
    margin: auto;
    object-fit: contain;
}

@media screen and (max-width:723px) {
    
    .carouselwrapper_container{
    
        padding:1rem 10rem;
    }
}
/*
.slick-slide{
    margin: 0 20px;
}
.slick-slide img{
    width: 100%;
}
.slick-slider{
    position: relative;
    display: block;
    box-sizing: border-box;
}
.slick-list{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-track{
    position: relative;
    top: 0;
    left: 0;
    display: block
}
.slick-slide{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
.slick-slide img{
    display: block;
}
.slick-initialized .slick-slide{
    display: block;
}
.copy{
    padding-top: 250px;
} */