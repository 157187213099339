
.contact_responsive
{
    flex-direction: column;
    width: 45%;
}


@media only screen and (max-width: 767px) {

    .contact_responsive{
        width: 100%;
    }
}