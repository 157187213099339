
.news-gallery .table_event .hoverable_title:hover{
    cursor: pointer;
    color: #0da1ff;
    transition: color 500ms;
}
.news-gallery-search_bar
{
    margin: 0 0 0 5vw;
}
.news-gallery .MuiTableCell-root
{
    font-family: Poppins;
}